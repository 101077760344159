<template>
  <div class="cs_faq">
    <template v-if="listArray.length > 0">
      <div class="box" v-for="list in listArray" :key="list.seq">
        <div class="top" @click="openContent($event)">
          {{ list.subject }}
          <router-link to="" @click.native="openContent($event)"
            ><img src="@/assets/images/sub/my_down_ico.png" alt=""
          /></router-link>
        </div>
        <div class="text" style="display: none" v-html="list.contents">
          {{ list.contents }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="box nodata">
        <p>자주하는 질문이 없습니다.</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: 0,
      category: parseInt(this.$route.params.category) || "카테고리 일반",
    };
  },
  mounted() {
    this.$store.dispatch("cs/dataClear");
    this.getList();
  },
  computed: {
    ...mapState("cs", ["result", "msg", "limit", "totalPage", "listArray"]),
  },
  methods: {
    openContent(e) {
      const textEl = e.target.closest(".cs_faq > .box").querySelector(".text");
      const imgEl = e.target.closest(".cs_faq > .box").querySelector("img");

      const upImg = require("@/assets/images/sub/my_up_ico.png");
      const downImg = require("@/assets/images/sub/my_down_ico.png");
      [...document.querySelectorAll(".box > .text")].map((text) => {
        if (textEl !== text) {
          text.style.display = "none";
        }
      });
      [...document.querySelectorAll(".cs_faq > .box")].map((box) => {
        let img = box.querySelector("img");
        if (imgEl !== img) {
          img.src = downImg;
        }
      });
      if (textEl.style.display === "none") {
        imgEl.src = upImg;
        textEl.style.display = "block";
      } else {
        imgEl.src = downImg;
        textEl.style.display = "none";
      }
    },
    day(str) {
      return this.$moment(str).format("YY-MM-DD");
    },
    async getList(reset = false) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("cs/getList", {
        category: this.category,
        page: this.page,
        id: "faq",
        reset,
      });
      this.page++;
      this.$emit("moreCheck", this.totalPage > this.page);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  &.nodata {
    text-align: center;
    padding: 15px 0;
  }
}
</style>
